body {
    font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    color: #555;
    font-weight: 600;
}

body,html{
  min-height: 100%;
}
::-webkit-scrollbar {
    display: none;
  }

.footer-content{
    width: 98%;
    // position: fixed;
    position: relative;
    bottom: 0;
    // left: 50%;
    // translate: -50% 0;
    display: grid;
    place-items: center;
    padding: 20px;
    border-top: 3px double #E5E5E5;
   margin: 0 auto;
}
.addModal {
  .modal-header {
    background: #307ecc;
    color: #fff;
    padding: 6px 15px;
  }
  .close {
    opacity: 0.9;
  }
}
.detailsTable {
  table {
      tr {
          th {
              padding: 5px !important;
              &.backHead {
                  text-align: center;
                  background: #7cc4f7 !important;
                  color: #fff !important;
              }
              &.layHead {
                  text-align: center;
                  background: #fe597b !important;
                  color: #fff !important;
              }
          }
          td {
              padding: 1px 5px !important;
              &.oddsBtn {
                  width: 10%;
                  border-right: solid 1px #fff;
                  border-left: solid 1px #fff;
                  text-align: center;
                  > strong {
                      font-size: 13px;
                      font-weight: 600;
                  }
                  > div {
                      font-size: 11px;
                      font-weight: 400;
                  }
                  &.back {
                      background: #aedbfb !important;
                  }
                  &.lay {
                      background: #ffcad5 !important;
                  }
              }
          }
          .suspended {
              position: absolute;
              width: 60%;
              height: 100%;
              right: 0;
              top: 0;
              background: rgba(0, 0, 0, 0.7);
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ff3660;
          }
      }
  }
  &.fancyTable {
    table {
        .suspended {
            width: 20%;
        }
    }
  }
}